import React from 'react';
import styles from 'styles/Footer.module.scss';
import abyssalLogo from 'assets/Abyssal_Logo_Circle.png';
import EmailIcon from 'assets/Footer_Email_Icon.png';

const Footer: React.FC = () => {
  return (
    <footer className={styles.Footer}>
      <div>
        <div className={styles.FooterLogoBox}>
          <img
            src={abyssalLogo}
            alt="Abyssal Logo"
            className={styles.FooterLogo}
          />
          <div className={styles.FooterSlogan}>여러분의 무한한 꿈과</div>
          <div className={styles.FooterSlogan}>어비셜이 함께합니다.</div>
        </div>
        <div className={styles.FooterContactUs}>
          <h3>CONTACT US</h3>
          <div className={styles.FooterContactTime}>
            <span>Mon - Fri</span>
            <span>9 PM ~ 6 AM</span>
          </div>
          <div className={styles.FooterContactTime}>
            <span>Sat - Sun</span>
            <span>9 PM ~ 3 AM</span>
          </div>
          <div className={styles.FooterContactEmail}>
            <img src={EmailIcon} />
            <span>kjs@abyssal.kr</span>
          </div>
        </div>
      </div>
      <div className={styles.FooterCopyright}>
        <span>© Copyright 2017 - {new Date().getFullYear()}</span>|
        <span>Abyssal Aerospace 대표자 강준서</span>|
        <span>All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
