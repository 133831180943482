import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from 'pages/Home';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import JoinUs from 'pages/JoinUs';
import Sponsor from 'pages/Sponsor';

const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path="/sponsor" element={<Sponsor />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
