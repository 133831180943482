import React, { useEffect } from 'react';
import styles from 'styles/Sponsor.module.scss';
import sponsor from 'assets/sponsor';
import { Fade } from 'react-awesome-reveal';

const Sponsor: React.FC = () => {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <section id="section1" className={styles.SponsorSection1}>
        <div style={{ whiteSpace: 'nowrap' }}>
          <div className={styles.SponsorTitleBox}>
            <h1>Abyssal Aerospace</h1>
            <div>후원 안내</div>
          </div>
        </div>
        <h1>어비셜과 함께, 우주의 꿈을 이루다.</h1>
        <h2>
          어비셜과 함께,
          <br />
          우주의 꿈을 이루다.
        </h2>
      </section>

      <section id="section2" className={styles.SponsorSection2}>
        <Fade duration={2000} triggerOnce cascade damping={0.4}>
          <h1>어비셜의 꿈과 함께하세요.</h1>
          <p>
            어비셜은 세계 최고. 최초를 향해 도전합니다.
            <br />
            후원을 통해 어비셜 항공우주와 도전을 함께하실 수 있습니다.
            <br />
            후원금은 각 프로젝트의 진행에 사용되며, 사용 내역은 투명하게
            공개됩니다.
          </p>
        </Fade>
      </section>

      <section id="section3" className={styles.SponsorSection3}>
        <div className={styles.SponsorSmallHeader}>
          <h2>후원 희망서</h2>
          <span>
            아래 입력란을 작성하여 제출하시면 담당자가 연락 드릴 예정입니다.
          </span>
        </div>

        <div className={styles.SponsorForm}>
          <iframe
            style={{ width: '100%', height: '100%', border: 'none' }}
            src={
              process.env
                .REACT_APP_FORM_SPONSOR /* 이곳에 후원 지원서 URL 입력 */
            }
          />
        </div>
      </section>

      <section id="section5" className={styles.SponsorSection5}>
        <h1>Sponsors</h1>
        <div className={styles.SponsorList}>
          <Fade duration={1000} triggerOnce>
            <div className={styles.SponsorListRow}>
              {sponsor.large.map((one, i) => (
                <div
                  className={styles.SponsorListCardLarge}
                  onClick={() => {
                    location.href = one.href;
                  }}
                >
                  <img src={one.src} />
                </div>
              ))}
            </div>
            <div className={styles.SponsorListRow}>
              {sponsor.medium.map((one, i) => (
                <div
                  className={styles.SponsorListCardMedium}
                  onClick={() => {
                    location.href = one.href;
                  }}
                >
                  <img src={one.src} />
                </div>
              ))}
            </div>
          </Fade>

          <hr />
          <div className={styles.SponsorListPersonRow}>
            {[
              '고남현',
              '김태우',
              '김규섭',
              '김동영',
              '김동현',
              '김동현',
              '김성준',
              '김재우',
              '김찬우',
              '박순창',
              '박정식',
              '백정빈',
              '양혜원',
              '유승재',
              '유태진',
              '이겸',
              '이상민',
              '장성진',
              '장호택',
              '조남석',
              '조현서',
              '천세범',
              '최정우',
              '전봉길',
              '홍예진',
              '홍순배',
              '오승아',
              '오승민',
              '3BM60',
              '김보민',
              '이종혁',
              '정원철',
              '이진',
              '강윤구',
              '하효창',
              '박종현',
              '김태우',
              '홍승표',
              '김경훈',
              '박경진',
              '오용기',
              '홍선우',
              '김태연',
              '손종희',
              '그린 브라우니',
              '이승환',
              '이찬솔',
              '장현준',
              '오동규',
              '김재연',
              '룬나이츠',
              '천재수',
              '모현성',
              '조윤주',
              '정민하',
              '이수현',
              '양윤제',
              '각혈염통',
              '박민철',
              '유현민',
              '김현우',
              '제페토 화니',
              '박두식',
              '송동윤',
              '강인서',
              '조단',
              '유소정',
              '잉여월드의 학초코',
              '신종민',
              '오정우',
              '김병주',
              '박시현',
              '한하늘',
              'Y',
              '박영빈',
              '민제원',
              '최재호',
              '최현민',
              '김성민',
              '오동권',
              'DUA',
              '박지애',
              '정성훈',
              '이현수',
              '나한균',
              '강희록',
              '주연',
              '전성현',
              '금동현',
              '류정원',
              '박재형',
            ].map((one, i) => (
              <div className={styles.SponsorListPerson}>
                <Fade duration={1000} triggerOnce delay={i * 25}>
                  <div>{one}</div>
                </Fade>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Sponsor;
