import React, { useEffect } from 'react';
import waveSvg from 'assets/wave.svg';
import ASL_S1 from 'assets/ASL_S1.png';
import ASL_S5 from 'assets/ASL_S5.png';
import ASLV_L1 from 'assets/ASLV_L1.png';
import styles from 'styles/Home.module.scss';
import rocketThumbnail from 'assets/Rocket_Video_Thumbnail.png';
import { Fade } from 'react-awesome-reveal';

const Home: React.FC = () => {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <section id="section1">
        <div className={styles.HomeTitleBox}>
          <h1>Abyssal Aerospace</h1>
          <div className="home-title-box-pc">
            당신의 무한한 꿈과 어비셜이 함께합니다.
          </div>
          <div className="home-title-box-mobile">
            당신의 무한한 꿈과
            <br />
            어비셜이 함께합니다.
          </div>
        </div>
        <img
          src={waveSvg}
          className="img-no-drag"
          style={{ width: '100%', height: 'auto' }}
        />
      </section>

      <section id="section2" className={styles.HomeSection2}>
        <h1>
          <Fade duration={1000} cascade damping={0.4}>
            <div>이루어질 것이다.</div>
            <div>우리가 원하기 때문에.</div>
          </Fade>
        </h1>
        <div className="home-description-pc">
          <Fade duration={1000} cascade delay={1000}>
            <div>
              <p>어비셜 항공우주는 2017년에 설립된 항공우주 연구조직입니다.</p>
              <p>
                국내의 아마추어 로켓 문화와 발사체 기술의 발전을 위해 노력하고
                있으며,
              </p>
              <p>
                현재까지 인공 당류 고체 추진체와 복합 추진제의 개발을 비롯하여{' '}
              </p>
              <p>
                극초음속 발사체와 항전장비 등 항공우주 분야의 다양한 기술을
                연구하고 있습니다.
              </p>
            </div>
          </Fade>
        </div>
        <div className="home-description-mobile">
          <Fade duration={1000} delay={1000}>
            <p>
              어비셜 항공우주는 2017년에 설립된 아마추어 항공우주 개발
              단체입니다. 국내의 아마추어 로켓 문화의 발달과 대한민국의 발사체
              기술의 발전을 도모하고 있으며, 우주를 꿈꾸는 아마추어 로켓
              개발자들이 모였습니다. 이들이 모여 인공 당류 고체 추진체를 비롯한
              위성 추력기, 항전 장비 등 항공우주 분야의 다양한 기술을 연구하고
              있습니다. 궁극적으로는 2027년까지 재활용 가능한 소형 발사체를
              개발하여, 100kg의 인공위성을 550km LEO에 안착시키는 것을 목표로
              하고 있습니다.
            </p>
          </Fade>
        </div>
      </section>

      <section id="section3" className={styles.HomeSection3}>
        <Fade duration={1000} direction="left" triggerOnce>
          <img id="ASL_S1" src={ASL_S1} className="img-no-drag" />
        </Fade>
        <div className={styles.HectorDescription}>
          <Fade duration={2000} triggerOnce>
            <h2>ASLV-S1</h2>
            <h1>Hector</h1>
            <p>
              ASLV-S1 : 헥터는 어비셜 항공우주의
              <br />첫 고체 추진 로켓 프로젝트입니다.
            </p>
            <p>
              엔지니어들은 우주로 나아갈 로켓을 만들기 위해
              <br />
              기초적인 기술이 필요하다고 생각했습니다.
            </p>
            <p>
              기본적인 발사와 추진 기술을 위한 발판이 필요했고
              <br />
              빠른 속도로 움직이는 로켓의 핵심을 살려
              <br />
              <b style={{ fontWeight: 600 }}>“음속에 가까운 발사체를 만들자”</b>
              <br />
              라는 목표를 잡았습니다.
            </p>
            <p>
              발사체의 부품들은 높은 신뢰성을 가져야 했고,
              <br />
              여러 상황에서 기계적 강도와 화학적 성질을
              <br />
              일정하게 유지할 수 있어야 했습니다.
            </p>
            <p>
              역시 가장 어려웠던 부분은
              <br />
              로켓의 심장, 엔진입니다.
            </p>
            <p>
              엔진은 높은 압력과 2000도에 가까운 고온을
              <br />
              견뎌야 했고, 플라스틱 소재부터 탄소강까지
              <br />
              여러 재질로 엔진을 만든 후 문제 환경에 맞춰
              <br />
              설계를 변경하며 시행착오를 극복해야 했습니다.
            </p>
            <p>
              마침내 개발을 마친 헥터는 2021년 8월 22일 오전 8시.
              <br />
              경기도 화성시 인근에서 성공적으로 발사되었습니다.
            </p>
          </Fade>
        </div>
      </section>

      <section id="section4" className={styles.HomeSection4}>
        <Fade duration={1000} triggerOnce>
          <h1>ASLV-S1 : 헥터의 추진 시험 영상을 확인해 보세요.</h1>
          <video
            autoPlay
            loop
            muted
            playsInline
            controlsList="nodownload"
            poster={rocketThumbnail}
          >
            <source src="/assets/Rocket_Video.mp4" type="video/mp4" />
            Video 태그를 지원하지 않는 브라우저입니다. 동영상을 재생할 수
            없습니다.
          </video>
          <p>
            헥터 엔진은 2021년 5월 진행한 4차 지상 연소시험에서
            <br />
            73기압 기준 84kgf의 추력을 발생시켰습니다.
            <br />
            헥터 프로젝트에서 얻은 기술적 실증은 다음 프로젝트로 이어집니다.
          </p>
        </Fade>
      </section>

      <section id="section5" className={styles.HomeSection5}>
        <div className={styles.TotalWarDescription}>
          <Fade duration={2000} triggerOnce>
            <h2>ASLV-S5</h2>
            <h1>Total War</h1>
            <p>
              ASLV-S5 : 총력전 프로젝트에서는 150km 이상 <br />
              도달 가능한 우주 발사체를 개발합니다.
            </p>
            <p>
              발사체는 지구와 우주를 나누는 경계인
              <br />
              칼만 라인을 넘어, 전 세계 아마추어 역사상
              <br />
              가장 높은 고도에 도달합니다.
            </p>
            <p>
              발사체가 목표 고도까지 도달하기 위해서는
              <br />
              평균 4톤의 추력을 내는 엔진이
              <br />
              8.3초간 연소해야 합니다.
            </p>
            <p>
              이전보다 훨씬 거대하고 강력한 엔진을 위해,
              <br />
              실리카/페놀릭 재질의 삭마 냉각 노즐과
              <br />
              탄소 섬유 복합재로 구성된 연소실이 엔진에 적용됩니다.
            </p>
            <p>
              발사체가 마하 7의 극초음속으로
              <br />
              지구 대기를 통과하는 동안,
              <br />
              동체는 2000K 이상의 온도와
              <br />
              대기 침식을 견딜 수 있어야합니다.
            </p>
            <p>
              엔지니어들은 내열 소재로 동체를 만들고
              <br />
              내부 공간에 단열재를 충전해 고온에서도
              <br />
              동체가 녹지 않도록 설계하였으며
            </p>
            <p>
              대기와 가장 직접적으로 부딪히는 노즈콘에는
              <br />
              특수 합금을 사용해 극한의 상황에서도
              <br />
              발사체가 안정성을 유지할 수 있도록 하였습니다.
            </p>
            <p>
              임무를 완료한 발사체는 지구로 낙하해
              <br />
              제주도 남단 공해상에서 회수됩니다.
            </p>
          </Fade>
        </div>
        <Fade duration={1000} direction="right" triggerOnce>
          <img id="ASL_S5" src={ASL_S5} className="img-no-drag" />
        </Fade>
      </section>

      <section id="section6" className={styles.HomeSection6}>
        <Fade duration={1000} direction="left" triggerOnce>
          <img id="ASLV_L1" src={ASLV_L1} className="img-no-drag" />
        </Fade>
        <div className={styles.OrcaDescription}>
          <Fade duration={2000} triggerOnce>
            <h2>ASLV-L1</h2>
            <h1>Orca</h1>
            <p>
              ASLV-L1 : 오르카는 30kg의 화물을 싣고
              <br />
              450km 준궤도 비행을 수행하도록 설계된
              <br />
              길이 8.5m, 직경 0.8m의 한국 최초 민간 우주 발사체입니다.
            </p>
            <p>
              생명유지장치로 생명체를 안전하게
              <br />
              귀환시키는 것이 이번 미션의 핵심입니다.
            </p>
            <p>
              엔진 설계부터 동체 및 위성 부품 제작까지
              <br />
              대학생들의 창의적 아이디어와 기술력으로 주도되었습니다.
            </p>
            <p>
              액체산소와 액체메탄을 연료로 사용하는 엔진은 높은 효율성을 띄며,
              <br />
              알루미늄과 CFRP(탄소섬유강화플라스틱) 복합재로 제작된 구조는
              <br />
              경량성과 강도를 모두 갖추고 있습니다.
            </p>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default Home;
