import React, { useEffect, useState } from 'react';
import styles from 'styles/Navbar.module.scss';
import navLogo from 'assets/Abyssal_Logo_TopBar.png';
import navToggleButton from 'assets/Navbar_Toggle_Button.png';

const Navbar: React.FC = () => {
  const { pathname } = window.location;

  const [menuOpen, setMenuOpen] = useState(false);

  const [hideNavUntilLoad, setHideNavUntilLoad] = useState(true);

  // 페이지 로드시 모바일 UI의 상단바 애니메이션 숨기기
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideNavUntilLoad(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={styles.NavbarStickToTop}>
      <div className={styles.Navbar}>
        <nav>
          <a href="/">
            <img src={navLogo} alt="Abyssal Logo" className={styles.NavLogo} />
          </a>
          <div className={styles.NavMenu}>
            <a href="/" className={pathname === '/' ? styles.NavActive : ''}>
              HOME
            </a>
            <a
              href="/join-us"
              className={pathname === '/join-us' ? styles.NavActive : ''}
            >
              JOIN US
            </a>
            <a
              href="/sponsor"
              className={pathname === '/sponsor' ? styles.NavActive : ''}
            >
              SPONSOR
            </a>
            <a
              href={process.env.REACT_APP_SHOP_URL}
              className={pathname === '/shop' ? styles.NavActive : ''}
            >
              SHOP
            </a>
          </div>
          <button
            className={styles.NavButtonToggle}
            type="button"
            onClick={() => {
              setMenuOpen(!menuOpen);
              setHideNavUntilLoad(false);
            }}
          >
            <img src={navToggleButton} alt="" />
          </button>
        </nav>

        {/* 모바일 UI: 토글 메뉴 */}
        <div
          className={
            menuOpen ? styles.NavMenuMobile : styles.NavMenuMobileClose
          }
          style={{ visibility: hideNavUntilLoad ? 'hidden' : 'visible' }}
        >
          <div>
            <a href="/" className={pathname === '/' ? styles.NavActive : ''}>
              HOME
            </a>
            <a
              href="/join-us"
              className={pathname === '/join-us' ? styles.NavActive : ''}
            >
              JOIN US
            </a>
            <a
              href="/sponsor"
              className={pathname === '/sponsor' ? styles.NavActive : ''}
            >
              SPONSOR
            </a>
            <a
              href={process.env.REACT_APP_SHOP_URL}
              className={pathname === '/shop' ? styles.NavActive : ''}
            >
              SHOP
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
